import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/bundle"
import "swiper/css/navigation"
import "swiper/css/pagination"

import { SelectSalonType } from "pages/auth/signIn/steps/SelectSalonType"
import { PersonalInfo } from "pages/auth/signIn/steps/PersonalInfo"
import { CodeConfirmation } from "widgets/auth/CodeConfirmation"
import { CreatePassword } from "widgets/auth/CreatePassword"
import { SelectBusinessCategory } from "pages/auth/signIn/steps/SelectBusinessCategory"
import "../style.css"

import { StepsBar } from "./StepsBar"
import {
  useConfirmCodeMutation,
  useRegisterMutation,
  useSendCodeMutation,
} from "../slice"
import { Footer } from "shared/ui/footer"
import Settings from "../../settings/index"
import RegisterSettings from "./steps/RegisterSettings"
import { toast } from "react-hot-toast"
import axios from "axios"

const user = JSON.parse(localStorage.getItem("user"))
const step = user?.step
export default function SignIn() {
  const navigate = useNavigate()
  const slidesRef = useRef(null)
  const [swiper, setSwiper] = useState()
  const [pageNum, setPageNum] = useState(1)
  const [hidden1, setHidden1] = useState(true)
  const [hidden2, setHidden2] = useState(true)
  const [hidden3, setHidden3] = useState(true)
  const [hidden4, setHidden4] = useState(true)

  const [sendCodeQuery] = useSendCodeMutation()
  const [confirmCodeQuery] = useConfirmCodeMutation()
  const [registerQuery] = useRegisterMutation()

  const [registerData, setRegisterData] = useState({
    name: "",
    surname: "",
    // email: "",
    phone: "",
    code: "",
    password: "",
    salon_type_id: 1,
    category_id: null,

    phoneError: null,
    codeError: null,
    passwordError: null,
    registerError: null,
  })

  function backSlide() {
    swiper.slidePrev()
    setPageNum(pageNum - 1)
    setRegisterData({
      ...registerData,
      codeError: null,
      phoneError: null,
      passwordError: null,
    })
  }

  function nextSlide() {
    swiper.slideNext()
    setPageNum(pageNum + 1)
  }

  async function sendCode({ name, surname, email, phone }) {
    setRegisterData({ ...registerData, phoneError: null })
    try {
      await sendCodeQuery({ phone, action: "register" }).unwrap()
      nextSlide()
      setHidden1(false)
      setRegisterData({
        ...registerData,
        phone,
        phoneError: null,
        name,
        surname,
        email,
      })
    } catch ({ data: { message } }) {
      setRegisterData({ ...registerData, phoneError: message })
    }
  }

  async function resent() {
    setRegisterData({ ...registerData, codeError: null })
    try {
      await sendCodeQuery({
        phone: registerData.phone,
        action: "register",
      }).unwrap()
    } catch ({ data: { message } }) {
      setRegisterData({ ...registerData, codeError: message })
    }
  }

  async function confirmCode(code) {
    setRegisterData({ ...registerData, codeError: null })
    try {
      await confirmCodeQuery({ phone: registerData.phone, code }).unwrap()
      setRegisterData({ ...registerData, code })
      nextSlide()
      setHidden2(false)
    } catch ({ data: { message } }) {
      setRegisterData({
        ...registerData,
        codeError: message ?? "Попробуйте ещё раз",
      })
    }
  }

  function createPassword(password) {
    setRegisterData({ ...registerData, password })
    nextSlide()
    setHidden3(false)
  }

  // function selectSalonType(id) {
  //   setRegisterData({ ...registerData, salon_type_id: id })
  //   nextSlide()
  //   setHidden4(false)
  // }

  async function selectCategory(id) {
    setRegisterData({ ...registerData, category_id: id })
    try {
      const res = await registerQuery({
        name: registerData.name,
        surname: registerData.surname,
        // email: registerData.email,
        phone: registerData.phone,
        code: registerData.code,
        password: registerData.password,
        salon_type_id: registerData.salon_type_id,
        category_id: id,
      }).unwrap()
      localStorage.setItem("accessToken", `Bearer ${res.data.accessToken}`)
      localStorage.setItem("salon_id", res.data.user?.salon_id)
      localStorage.setItem("user", JSON.stringify(res.data.user))
      localStorage.setItem("role_name", res.data.user?.roles[0]?.name)
      if (res?.data?.user?.step === "0") {
        navigate("/settings")
      } else if (res?.data?.user?.step === "1") {
        navigate("/categories")
      } else if (res?.data?.user?.step === "2") {
        navigate("/services")
      } else if (res?.data?.user?.step === "3") {
        navigate("/masters")
      } else {
        navigate("/bookings")
      }

      window.location.reload()
    } catch (error) {
      setRegisterData({
        ...registerData,
        registerError: "Что-то пошло не так, попробуйте позже",
      })
    }
  }

  const [mounted, setMounted] = useState(false)

  function goToLogin() {
    setMounted(false)
    setTimeout(() => {
      navigate("/login")
    }, 500)
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className='flex signInSliderWrap auth'>
      {/* slider progress bar */}
      <StepsBar mounted={mounted} pageNum={pageNum} />

      {/* slider */}
      <div
        ref={slidesRef}
        className={`${
          mounted ? "opacity-100" : "opacity-0"
        } transition-opacity duration-500 flex flex-1 relative justify-center`}
      >
        <Swiper
          className='signInSlider'
          modules={[Pagination]}
          direction={"vertical"}
          allowTouchMove={false}
          onSwiper={setSwiper}
        >
          <SwiperSlide>
            <PersonalInfo
              backSlide={goToLogin}
              submit={sendCode}
              errorMsg={registerData.phoneError}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CodeConfirmation
              hidden1={hidden1}
              submit={confirmCode}
              backSlide={backSlide}
              errorMsg={registerData.codeError}
              resent={resent}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CreatePassword
              hidden2={hidden2}
              submit={createPassword}
              backSlide={backSlide}
              errorMsg={registerData.passwordError}
            />
          </SwiperSlide>
          {/* <SwiperSlide>
            <SelectSalonType
              hidden3={hidden3}
              backSlide={backSlide}
              submit={selectSalonType}
            />
          </SwiperSlide> */}
          <SwiperSlide>
            <SelectBusinessCategory
              hidden4={hidden3}
              submit={selectCategory}
              backSlide={backSlide}
              errorMsg={registerData.registerError}
            />
          </SwiperSlide>
        </Swiper>
        <Footer />
      </div>
    </div>
  )
}