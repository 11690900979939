import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
// import { useDispatch, useSelector } from "react-redux"

import {
  ContentPasteOutlined,
  ExpandMore,
  HeadsetMicOutlined,
  HomeRepairServiceOutlined,
  Notifications,
  PeopleOutlineOutlined,
  RecordVoiceOverOutlined,
  SettingsOutlined,
  WindowOutlined,
} from "@mui/icons-material"
import { Menu } from "@mui/icons-material"
import {
  Avatar,
  Button,
  IconButton,
  Menu as MenuMUI,
  MenuItem as MenuItemMUI,
} from "@mui/material"

import { updatedIsAuth } from "app/homepage/slice"
import { getPaths } from "app/homepage/routes"
import { BasicMenu } from "shared/ui/menu"
import { ChangePass } from "../../features/headerMenu/ChangePass"
import { ChangePhone } from "../../features/headerMenu/ChangePhone"
import "./style.css"
import { observer } from "mobx-react-lite"
import { useStore } from "hooks"
import { useSelector } from "react-redux"

const currentPaths = [
  // {
  //   path: "/bookings-calendar",
  //   value: "Расписание",
  //   Icon: CalendarTodayOutlined,
  //   permission: false,
  // },
  {
    path: "/bookings",
    value: "Записи",
    Icon: ContentPasteOutlined,
    permission: null,
  },
  // {
  //   path: "/notifications",
  //   value: "Уведомления",
  //   Icon: NotificationsNoneOutlined,
  //   permission: false,
  // },
  {
    path: "/customers",
    value: "Клиенты",
    Icon: RecordVoiceOverOutlined,
    permission: null,
  },
  {
    path: "/masters",
    value: "Сотрудники",
    Icon: PeopleOutlineOutlined,
    permission: null,
  },
  {
    path: "/categories",
    value: "Категории услуг",
    Icon: WindowOutlined,
    permission: null,
  },
  {
    path: "/services",
    value: "Услуги",
    Icon: HomeRepairServiceOutlined,
    permission: null,
  },
  {
    path: "/feedback",
    value: "Обратная связь",
    Icon: HeadsetMicOutlined,
    permission: null,
  },
  {
    path: "/settings",
    value: "Настройки",
    Icon: SettingsOutlined,
    permission: null,
  },
  // {
  //   path: "/profile",
  //   value: "Профиль",
  //   Icon: PersonOutlineOutlined,
  //   permission: null,
  // },
  {
    path: "/templates",
    value: "Шаблоны",
    permission: null,
  },
  {
    path: "/analyzes",
    value: "Анализы",
    permission: null,
  },
  {
    path: "/analyzes/new",
    value: "Анализы",
    permission: null,
  },
  {
    path: "/analyzes/:id",
    value: "Анализы",
    permission: null,
  },
  // {
  //   path: "/profile",
  //   value: "Профиль",
  //   Icon: PersonOutlineOutlined,
  //   permission: null,
  // },
]

export const HeaderMenu = observer(({ toggleNavbar }) => {
  // const { auth } = useStore()
  const { settings } = useSelector((state) => state.profileReducer)
  const { auth } = useStore()
  const navigate = useNavigate()
  const isMobile = window.screen.width < 500

  // const user = useSelector(state => state.main.user)

  const [toggle, setToggle] = useState(true)

  function toggleMenu() {
    setToggle(!toggle)
  }

  // const notificationsCount = useSelector(state => state...)
  // const personal = useSelector(state => state...)
  const count = 30

  const location = window.location.pathname
  const paths = getPaths(null)

  const [currentPath, setCurrentPath] = useState("")

  useEffect(() => {
    const currentPathInfo = currentPaths.filter(({ path }) => path === location)
    const currentPathValue = currentPathInfo.length
      ? currentPathInfo[0].value
      : undefined
    setCurrentPath(currentPathValue)
    document.title = currentPathValue ?? "Navbat"
  }, [location])

  //   useEffect(() => {
  //   const location = window.location.pathname
  //   const currentPathInfo = currentPaths.filter(({ path }) => path === location)
  //   const currentPathValue = currentPathInfo.length ? currentPathInfo[0].value : undefined
  //   setCurrentPath(currentPathValue)
  //   document.title = currentPathValue ?? "Navbat"
  // }, [location])

  const [activePassword, setActivePassword] = useState(false)
  const [activeTel, setActiveTel] = useState(false)

  function onChooseOption(option) {
    switch (option) {
      case "Профиль":
        navigate("profile")
        break
      case "Сменить пароль":
        setActivePassword(true)
        break
      case "Сменить Номер телефона":
        setActiveTel(true)
        break
      case "Выход":
        // dispatch(updatedIsAuth(false))
        localStorage.removeItem("accessToken")
        localStorage.removeItem("user")
        localStorage.removeItem("role_name")
        window.location.reload()
        // navigate("/login")
        break
      default:
        break
    }
    toggleMenu()
  }

  const options = [
    { title: "Профиль", link: "/profile" },
    { title: "Сменить пароль", link: "/changePass" },
    // { title: "Сменить Номер телефона", link: "/changeTel" },
    { title: "Выход", link: "/logout" },
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className='
        headerWrap sm:static fixed w-full left-0 top-0 z-20
        flex justify-between items-center h-[68px] sm:h-fit py-2 px-4
        bg-blue sm:bg-white mb-0 sm:mb-5 sm:rounded-md
      '
      >
        {/* burger menu */}
        <div onClick={toggleNavbar} className='sm:hidden'>
          <Menu sx={{ color: "white" }} />
        </div>

        <p className='sm:block sm:text-base text-lg text-orange font-medium sm:text-blue'>
          {currentPath}
        </p>
        <div className='flex  '>
          {/*<div>*/}
          {/*  <Button*/}
          {/*    onClick={() => navigate("/notifications")}*/}
          {/*    variant="contained"*/}
          {/*    startIcon={<Notifications />}*/}
          {/*    color={"secondary"}*/}
          {/*    style={{*/}
          {/*      borderRadius: 100,*/}
          {/*      paddingTop: "6px",*/}
          {/*      paddingBottom: "6px",*/}
          {/*      marginRight: isMobile ? 0 : "25px",*/}
          {/*      height: "fit-content",*/}
          {/*      alignItems: "center",*/}
          {/*      justifyContent: "center",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {count > 0 && (*/}
          {/*      <span className="self-center text-xs">{count}</span>*/}
          {/*    )}*/}
          {/*  </Button>*/}
          {/*</div>*/}
          <div className=' hidden sm:flex  bg-white items-center sm:text-base text-medium font-medium whitespace-nowrap h-full'>
            <BasicMenu
              className='max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden'
              options={options}
              // title={`${auth?.user?.name} ${auth?.user?.surname || ""}`}
              title={`${settings?.name || auth.user?.name} ${settings?.surname || auth.user?.surname || ""}`}
              withIcon={<ExpandMore />}
              chooseOption={onChooseOption}
            />
          </div>
          <div className='flex sm:hidden rounded-2xl bg-white items-center sm:text-base text-medium font-medium whitespace-nowrap h-full'>
            <IconButton onClick={handleMenu}>
              <Avatar>{auth?.user?.name?.charAt(0) || settings?.name?.charAt(0)}</Avatar>
              {/* <Avatar alt="User Avatar" src={auth.user.images} /> */}
            </IconButton>
            <MenuMUI
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {options.map((option) => (
                <MenuItemMUI
                  key={option.title}
                  onClick={() => {
                    onChooseOption(option.title)
                    handleClose()
                  }}
                >
                  {option.title}
                </MenuItemMUI>
              ))}
            </MenuMUI>
          </div>
        </div>
      </div>
      {activePassword && (
        <ChangePass active={activePassword} setActive={setActivePassword} />
      )}
      {activeTel && <ChangePhone active={activeTel} setActive={setActiveTel} />}
    </>
  )
})