import { MasterForms } from "entities/masters/MasterForms"
import {
  usePostAvatarMutation,
  useUpdateMasterMutation,
} from "pages/masters/slice"
import { viewModel } from "../model/helpers"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { setStep } from "../../../app/homepage/stepSlice"
import { currentUrl } from "../../../url"

const sections = [
  "Личные данные",
  "Настройки",
  "Услуги",
  "График работы",
  "Соцсети",
]
const user = JSON.parse(localStorage.getItem("user"))
const id = user?.id
const token = localStorage.getItem("accessToken")
const salonTypeId = user?.salon?.salon_type_id
export function EditMaster({ active, setActive, master, mastersRefetch }) {
  const dispatch = useDispatch()
  const { step } = useSelector((state) => state.StepSliceReducer)
  const [selectedSection, setSelectedSection] = useState(sections[0])
  const [updateMasterQuery, { isLoading: editMasterLoading, isError, error }] =
    useUpdateMasterMutation()
  const [postAvatar, { isLoading: postAvatarLoading }] = usePostAvatarMutation()
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedPortfolio, setSelectedPortfolio] = useState([])
  const [emailError, setEmailError] = useState(false)
  const [salonId, setSalonId] = useState(0)

  useEffect(() => {
    const salon = JSON.parse(localStorage.getItem("user"))
    setSalonId(salon?.salon?.id)
  })

  const onAvatarDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      if (
        ![
          "image/jpeg",
          "image/png",
          "image/webp",
          "image/jpg",
          "image/svg",
          "image/bmp",
          "image/heic",
        ].includes(file.type)
      ) {
        toast.error(
          "Недопустимый формат файла. Разрешены только форматы JPEG, PNG, WEBP, JPG, SVG, BMP, HEIC "
        )
        return
      }

      // Проверка размера файла
      if (file.size > 50 * 1024 * 1024) {
        toast.error("Файл слишком большой. Максимальный размер 50 МБ.", {})
        return
      }

      setSelectedPhoto(file)
    }
  }, [])

  async function updateMaster(editedMaster) {
    setEmailError(false)
    try {
      // if(editedMaster?.email === '') {
      //   delete editedMaster.email
      // }

      const result = { ...editedMaster }

      // await updateMasterQuery(result)
      try {
        const response = await updateMasterQuery(result)
        if (response.error) {
          throw new Error(response.error.data.message)
        }
      } catch (error) {
        const formatErrorMessage = (message) => {
          const filteredMessage = message.split(".")[0] + "."
          return filteredMessage
        }

        toast.error(formatErrorMessage(error.message))

        return
      }

      if (step === "3") {
        const res = await axios.put(
          `${currentUrl}/master/specialists/${id}`,
          { step: "4" },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        dispatch(setStep("4"))
        localStorage.setItem(
          "user",
          JSON.stringify(res?.data?.data?.specialist)
        )
      }
      if (selectedPhoto) {
        try {
          const formData = new FormData()
          formData.append("file", selectedPhoto)
          formData.append("collection_name", "avatar")
          formData.append("model_id", editedMaster.id)
          await postAvatar({ id: salonId, formData }).unwrap()
        } catch (err) {
          toast.error("Ошибка при изменении аватара")
        }
      }
      if (selectedPortfolio?.length > 0) {
        try {
          selectedPortfolio.forEach((item) => {
            ;(async function () {
              const formData = new FormData()
              formData.append("file", item)
              formData.append("collection_name", "portfolio")
              formData.append("model_id", editedMaster.id)
              await postAvatar({ id: salonId, formData }).unwrap()
            })()
          })
        } catch (err) {
          toast.error("Ошибка при изменении портфолио")
        }
      }
      setActive(false)
      setTimeout(() => {
        // window.location.reload()
        toast.success("Сотрудник изменен", { position: "bottom-right" })
      }, 400)
      mastersRefetch()
    } catch (error) {
      if (error?.data?.message === "The phone has already been taken.") {
        toast.error("Данный номер телефона уже зарегестрирован")
      } else if (error?.data?.message === "The email has already been taken.") {
        toast.error("Данный email уже зарегестрирован", {
          position: "bottom-right",
        })
        setSelectedSection(sections[0])
        setEmailError(true)
      } else {
        toast.error("Ошибка при изменении сотрудника")
      }
    }
  }

  return (
    <MasterForms
      mastersRefetch={mastersRefetch}
      setEmailError={setEmailError}
      sections={sections}
      emailError={emailError}
      selectedSection={selectedSection}
      setSelectedSection={setSelectedSection}
      reqLoading={editMasterLoading}
      avatarLoading={postAvatarLoading}
      selectedPhoto={selectedPhoto}
      selectedPortfolio={selectedPortfolio}
      setSelectedPhoto={setSelectedPhoto}
      setSelectedPortfolio={setSelectedPortfolio}
      active={active}
      setActive={setActive}
      masterData={master}
      onSubmit={updateMaster}
      onAvatarDrop={onAvatarDrop}
    />
  )
}