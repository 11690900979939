import { toast } from "react-hot-toast"
import { BookingsService } from "resources"
import { DeleteModal } from "shared/ui/modal/DeleteModal"
import { useDispatch } from "react-redux"
import { fetchBookings } from "../../../pages/bookings/bookingSlice.ts"
import { useDeleteBookingMutation } from "../../../pages/bookings/newBookingApi"

export function RemoveBooking({ booking, active, setActive, refetch }) {
  // const dispatch = useDispatch()
  const [deleteBooking, { isLoading }] = useDeleteBookingMutation()

  const handleDelete = async () => {
    try {
      await deleteBooking(booking.id)
      toast.success("Успешно удалено", { position: "bottom-right" })
      setActive(false)
      if (refetch) {
        refetch()
      }
      // dispatch(fetchBookings({search: '', startDate: '', endDate: ''}))
    } catch (error) {
      toast.error("Ошибка при удалении записи")
      console.error(error.message)
    }
  }

  return (
    <DeleteModal
      active={active}
      setActive={setActive}
      loading={isLoading}
      text='Вы действительно хотите удалить запись?'
      onSubmit={handleDelete}
    />
  )
}
