import { flow, types } from "mobx-state-tree"
import { BasePageStore } from "./BasePageStore"
import { CategoryModel } from "store/Instances/CategoryModel"
import { CategoriesService } from "resources"
import {
  convertCategoriesResponseToSnapshots,
  convertServiceResponseToSnapshots,
} from "utils"
import { ServiceModel } from "store/Instances/ServiceModel"
import { toast } from "react-hot-toast"

export const CategoriesPageStore = types
  .compose(
    BasePageStore,
    types.model("CategoriesPageStore", {
      categories: types.array(CategoryModel),
      services: types.array(ServiceModel),
      isLoading: types.optional(types.boolean, false),
    })
  )
  .views((self) => ({
    get query() {
      return {
        page: self.currentPage,
        per_page: self.rowsPerPage,
        search: self.searchString,
      }
    },
  }))
  .actions((self) => ({
    getCategories: flow(function* getCategories() {
      try {
        self.isLoading = true
        const response = yield CategoriesService.getCategories()
        const services = (response.data.data ?? []).flatMap(
          (item) => item["sub-category"].service
        )

        self.services = convertServiceResponseToSnapshots(services)
        self.categories = convertCategoriesResponseToSnapshots(
          response.data.data ?? []
        )
      } catch (error) {
        console.error(error)
      }
      self.isLoading = false
    }),
  }))
  .actions((self) => ({
    reload() {
      self.getCategories()
    },
  }))
  .actions((self) => ({
    createCategory: flow(function* (data) {
      try {
        yield CategoriesService.createCategory({ name: data.name })
        toast.success("Категория успешно создана!", {
          position: "bottom-right",
        })
        self.reload()
      } catch (error) {
        console.error(error.message)
        toast.error("Не удалось создать. Попробуйте позже!")
      }
    }),
    editCategory: flow(function* (data) {
      try {
        yield CategoriesService.updateCategory({ id: data.id, name: data.name })
        toast.success("Категория успешно изменена!", {
          position: "bottom-right",
        })
        self.reload()
      } catch (error) {
        console.error(error.message)
        toast.error("Не удалось создать. Попробуйте позже!")
      }
    }),
    afterCreate() {
      self.getCategories()
    },
  }))