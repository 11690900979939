import React, { useEffect, useState } from "react"
import banner from "../../assets/salonsOwner.png"
import logo from "../../assets/logo.svg"
import { InputBase } from "shared/ui/input/InputBase"
import { MyController } from "pages/settings/MyController"
import { useForm } from "react-hook-form"
import {
  useGetReastaurantByHashQuery,
  useUpdateRestaurantMutation,
} from "./restaurantSlice"
import { useParams } from "react-router-dom"
import toast from "react-hot-toast"
import NotFound from "pages/clientConfirmation/404"
import ChangeStatus from "pages/clientConfirmation/changeStatus"
const RestaurantSms = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    required,
    control,
    trigger,
    watch,
  } = useForm()
  const { hash } = useParams()
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [isCancelDisabled, setIsCancelDisabled] = useState(false)
  const [show404, setShow404] = useState(false)
  const {
    data: restaurantData,
    refetch,
    isLoading,
    error: restaurantError,
  } = useGetReastaurantByHashQuery(hash)
  const [updateHashBooking, { isSuccess, isError }] =
    useUpdateRestaurantMutation()
  const [statusId, setStatusId] = useState(restaurantData?.booking?.status_id)

  const onSubmit = async (formData) => {
    const payload = {
      place_number: formData?.place,
      status_id: 8,
      start: restaurantData?.booking?.start,
    }

    try {
      await updateHashBooking({
        body: payload,
        bookingId: restaurantData?.booking?.id,
        hash,
      }).unwrap()
      refetch()
      setValue("place", formData.place)
      toast.success("Запись успешно подтверждена")
    } catch (error) {
      toast.error(error?.data?.message || "Ошибка при подтверждении записи")
    }
  }

  const onCancel = async () => {
    const payload = {
      status_id: 3,
      start: restaurantData?.booking?.start,
    }

    try {
      await updateHashBooking({
        body: payload,
        bookingId: restaurantData?.booking?.id,
        hash,
      }).unwrap()

      setIsCancelDisabled(true)
      toast.success("Запись успешно отменена")
    } catch (error) {
      toast.error(error?.data?.message || "Ошибка при отмене записи")
    }
  }

  const currentStatus = restaurantData?.booking?.status?.id
  const isCancelled = currentStatus === 3
  const isConfirmed = currentStatus === 8

  useEffect(() => {
    if (restaurantData && restaurantData.booking?.place_number) {
      setValue("place", restaurantData.booking.place_number)
    }
  }, [restaurantData, setValue])

  useEffect(() => {
    if (isSuccess || isCancelled || isConfirmed) {
      setIsFormDisabled(true)
    }
  }, [isSuccess, isCancelled, isConfirmed])

  useEffect(() => {
    if (currentStatus === 3) {
      setIsCancelDisabled(true)
    }
  }, [restaurantData])

  useEffect(() => {
    if (restaurantError) {
      if (restaurantError?.status === 404) {
        setShow404(true)
      }
    }
  }, [restaurantError])
  console.error(restaurantError, "restaurantData")

  if (restaurantError) {
    return <NotFound title={"Запись не найдена или истекла"} />
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=' w-full flex flex-col items-center bigMobile:gap-2 !overflow-y-auto no-scrollbar bigMobile:h-[100vh] h-full bg-[#FFFFFF] bigMobile:overflow-hidden mobile:mb-4'
    >
      <div className='text-center  w-full bigMobile:h-[55px] mobile:h-[65px] bg-[#1E3148] text-white bigMobile:p-2 py-4   '>
        <img
          src={logo}
          alt=''
          className='flex justify-center items-center mx-auto'
        />
      </div>
      <div className='bg-white p-2 py-4  flex flex-col gap-6  mb-4 w-full  max-w-[358px]  '>
        <div className='flex flex-col justify-between gap-6'>
          <div className='flex  bg-[#F6FAFF] h-[52px] justify-between  mx-auto w-full p-2 rounded-lg '>
            <div>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                Заявка оставлена для
              </p>
              <p className='font-medium text-medium '>
                {restaurantData?.restaurant?.name}
              </p>
            </div>
          </div>
          <div className='flex flex-col bg-[#F6FAFF]  justify-between  mx-auto my-auto h-[120px] w-full p-2 rounded-lg '>
            <div className='flex justify-between mx-auto w-full  '>
              <div>
                <p className='text-gray-600 text-small flex  font-normal text-[#A4AFBD] '>
                  Клиент
                </p>
                <p className='font-medium text-medium '>
                  {restaurantData?.client?.name}
                </p>
              </div>
              <div className='text-end'>
                <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                  Телефон
                </p>
                <p className='font-medium text-medium'>
                  {restaurantData?.client?.phone}
                </p>
              </div>
            </div>
            <div className='flex justify-between mx-auto w-full  '>
              <div>
                <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                  Дата и время
                </p>
                <p className='font-medium text-medium '>
                  {restaurantData?.booking?.start}
                </p>
              </div>
              <div className='text-end'>
                <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                  Количество гостей
                </p>
                <p className='font-medium text-medium'>
                  {restaurantData?.booking?.guest_count}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='  w-full mx-auto flex flex-col    justify-between gap-4'>
          <div>
            <MyController
              name='place'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputBase
                  {...field}
                  required={true}
                  disabled={isFormDisabled}
                  inputClassName='inputOutlined'
                  labelClassName='sm:mr-5'
                  placeholder='Введите номер место'
                  labelText='Место'
                />
              )}
            />
            {/* {errors.street && (
              <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
            )} */}
          </div>

          <button
            type='submit'
            className={`px-4 py-2 w-full rounded-lg text-white ${
              isFormDisabled ? "bg-darkGray" : "bg-darkOrange"
            }`}
            disabled={isFormDisabled}
          >
            Подтвердить запись
          </button>

          <button
            onClick={() => setShowCancel(true)}
            type='button'
            className={`px-4 py-2 w-full rounded-lg font-medium border-darkGray border ${
              isCancelDisabled && "text-darkGray"
            }`}
            disabled={isCancelDisabled}
          >
            Отменить запись
          </button>
        </div>
        <span className='tableRowLine w-full'></span>
        <a href='https://navbat.tj' target='_blank' rel='noopener noreferrer'>
          <img src={banner} alt='owner' className='w-full' />
        </a>
      </div>
      {showCancel && (
        <ChangeStatus
          isError={isError}
          isSuccess={isSuccess}
          setActive={setShowCancel}
          onClose={() => setShowCancel(false)}
          active={showCancel}
          onConfirm={onCancel}
          closeStatusModal={() => setShowCancel(false)}
          currentStatusId={statusId}
          text={"Вы уверены что хотите отменить запись?"}
          successLabel={"Ваша запись успешно отменена"}
          errorLabel={"При отмене произошла ошибка"}
        />
      )}
    </form>
  )
}

export default RestaurantSms