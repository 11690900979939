import { useCallback, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { fetchSettings, postMedia, postSettings } from "./settingsSlice.ts"

import { TextArea } from "shared/ui/input/TextArea"
import { InputBase } from "shared/ui/input/InputBase"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { CloudUpload, PhotoCameraOutlined } from "@mui/icons-material"
import { useDropzone } from "react-dropzone"
import { fetchSpheres } from "./spheresSlice.ts"
import { fetchCities } from "./citiesSlice.ts"
import { TableSpinner } from "../../shared/ui/dataTable/spinner"
import { MyController } from "./MyController"
import CloseIcon from "@mui/icons-material/Close"
import axios from "axios"
import { FirstStepModal } from "./FirstStepModal"
import { AfterFirstStepModal } from "./AfterFirstStepModal"
import { setStep } from "../../app/homepage/stepSlice"
import { currentUrl } from "../../url"
import { useGetDeepLinkQuery, useGetSpheresQuery } from "./settingsApi.js"
import { PhoneInput } from "shared/ui/input/PhoneInput.jsx"
import { useGetMeQuery } from "pages/profile/userProfileApi.js"
import { baseQueryWithReauth, getApiConfig } from "shared/api"
import DeepLinkRedirect from "./redirectDeepLink.jsx"
const accessToken = localStorage.getItem("accessToken")
const user = JSON.parse(localStorage.getItem("user"))
const id = user?.id
const token = localStorage.getItem("accessToken")
const category_id = JSON.parse(localStorage.getItem("category_id"))
  ? JSON.parse(localStorage.getItem("category_id"))
  : user?.restaurant?.category_id

const restaurant_id = JSON.parse(localStorage.getItem("restaurant_id"))
  ? JSON.parse(localStorage.getItem("restaurant_id"))
  : user?.restaurant_id

const salon_id = user?.salon_id || user?.restaurant_id

export default function Settings() {
  const dispatch = useDispatch()
  const { list, loading, error, postLoading, mediaLoading } = useSelector(
    (state) => state.settingsSliceReducer
  )

  console.log(salon_id, "testtt")

  useEffect(() => {
    dispatch(fetchSettings(salon_id))
  }, [dispatch])

  console.log(list, "list")

  const settings = useSelector((state) => state.settings)
  const { spheres, spheresLoading, spheresError } = useSelector(
    (state) => state.spheresSliceReducer
  )
  const { cities, citiesLoading, citiesError } = useSelector(
    (state) => state.citiesSliceReducer
  )
  const { data: userProfile } = useGetMeQuery({ id })
  const { step } = useSelector((state) => state.StepSliceReducer)

  const userSurname = userProfile?.data

  const [salonId, setSalonId] = useState("")
  const [restaurantId, setRestaurantId] = useState("")
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [portfolio, setPortfolio] = useState([])
  const [openFirst, setOpenFirst] = useState(false)
  const [openAfter, setOpenAfter] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [deepLinkData, setDeepLinkData] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    trigger,
    watch,
  } = useForm()

  const phoneNumber = watch("phone")

  // Делаем апрос на получение deepLink (потом надо будет переписать в redux)

  // useEffect(() => {
  //   const fetchDeepLink = async () => {
  //     try {
  //       // Replace with a default if needed
  //       const response = await fetch(
  //         `https://test.api.navbat.tj/api/client/v1/deep-link?slug=${slug}`,
  //         {
  //           headers: {
  //             "Navbat-key": "kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr",
  //             Accept: "application/json",
  //           },
  //         }
  //       )

  //       if (!response.ok) {
  //         throw new Error(`Ошибка: ${response.status}`)
  //       }

  //       const result = await response.json()
  //       setDeepLinkData(result) // Сохраняем данные в состоянии
  //       console.log(result, "fetch result")
  //     } catch (error) {
  //       console.error("Error fetching deep link:", error)
  //     }
  //   }

  //   if (list?.deep_link) {
  //     fetchDeepLink()
  //   }
  // }, [list?.deep_link])

  const onAvatarDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      if (
        ![
          "image/jpeg",
          "image/png",
          "image/webp",
          "image/jpg",
          "image/svg",
          "image/bmp",
          "image/heic",
        ].includes(file.type)
      ) {
        toast.error(
          "Недопустимый формат файла. Разрешены только форматы JPEG, PNG, WEBP, JPG, SVG, BMP, HEIC "
        )
        return
      }

      // Проверка размера файла
      if (file.size > 50 * 1024 * 1024) {
        toast.error("Файл слишком большой. Максимальный размер 50 МБ.")
        return
      }

      setSelectedPhoto(file)
    }
  }, [])
  useEffect(() => {
    if (!loading) {
      setIsLoaded(true)
    }
  }, [loading])

  useEffect(() => {
    if (isLoaded) {
      if (step === "0" && category_id !== 6) {
        setOpenFirst(true)
      }
    }
  }, [step, isLoaded])

  useEffect(() => {
    if (isLoaded) {
      if (step === "1" && phoneNumber && category_id !== 6) {
        setOpenAfter(true)
      }
    }
  }, [step, phoneNumber, isLoaded])

  const { getRootProps: avatarRootProps, getInputProps: avatarInputProps } =
    useDropzone({ onDrop: onAvatarDrop })

  const onPortfolioDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setPortfolio(acceptedFiles)
    }
  }, [])

  const {
    getRootProps: portfolioRootProps,
    getInputProps: portfolioInputProps,
  } = useDropzone({ onDrop: onPortfolioDrop, multiple: true })

  const deleteOnePhoto = (item) => {
    setPortfolio(portfolio.filter((obj) => obj !== item))
  }

  const setValues = () => {
    setValue("name", list?.name)
    setValue("phone", `${list?.phone}`)
    setValue("description", list?.description)
    setValue("street", list?.street)
    setValue("building", list?.building)
    setValue("website", list?.website)
    setValue("facebook", list?.facebook)
    setValue("instagram", list?.instagram)
    setValue("city_id", list?.city_id)
  }

  useEffect(() => {
    if (userSurname) {
      setValue("surname", userSurname.surname)
      dispatch(fetchSettings(salonId))
    }
  }, [userSurname, setValue, salonId, dispatch])

  useEffect(() => {
    // setSalonId(String(user?.salon_id || user?.restaurant_id))
    setRestaurantId(String(salon_id))
    if (salon_id) {
      dispatch(fetchSettings(String(salon_id)))
    }

    dispatch(fetchSpheres())
    dispatch(fetchCities())
  }, [step])

  useEffect(() => {
    setValues("workSchedules", list?.workSchedules)
  }, [list?.workSchedules])

  useEffect(() => {
    if (list) {
      setValues()
    }
  }, [list])

  useEffect(() => {
    setValues()
    if (!loading && !spheresLoading) {
      const currentSphere = spheres?.filter(
        (item) => item?.category?.id === list?.category_id
      )
      setTimeout(() => {
        setValue("category", currentSphere[0]?.category?.name)
      }, 0)
    }
  }, [list, setValue, loading, spheresLoading, step])

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    await trigger()
    try {
      dispatch(
        postSettings({
          category_id: list?.category_id,
          id: Number(salon_id),
          ...data,
        })
      )

      // if (selectedPhoto) {
      //   dispatch(
      //     postMedia({
      //       file: selectedPhoto,
      //       collection_name: "avatar",
      //       model_id: salonId,
      //     })
      //   )
      // }

      const isRestaurant = user?.restaurant_id
      if (selectedPhoto) {
        const formData = new FormData()
        formData.append("file", selectedPhoto)
        formData.append("collection_name", "avatar")
        formData.append("model_id", salon_id)

        const url = isRestaurant
          ? `${currentUrl}/master/restaurants/${salon_id}/update-image`
          : `${currentUrl}/master/salons/${salon_id}/update-image`

        const response = await axios.post(
          url,
          // `${currentUrl}/master/salons/${salonId || 0}/update-image` || `${currentUrl}/master/restaurants/${salonId || 0}/update-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.error) {
          throw new Error("Ошибка при загрузке аватарки")
        }
      }
      if (portfolio.length > 0) {
        portfolio.forEach((item, index) => {
          dispatch(
            postMedia({
              file: item,
              collection_name: "portfolio",
              model_id: salonId,
            })
          )

          if (index === portfolio.length - 1) {
            setTimeout(() => {
              dispatch(fetchSettings(salonId))
              setPortfolio([])
            }, 1000)
          }
        })
      }

      if (step === "0") {
        const res = await axios.put(
          `${currentUrl}/master/specialists/${id}`,
          { step: "1" },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        if (res.status === 202) {
          dispatch(setStep("1"))
          localStorage.setItem(
            "user",
            JSON.stringify(res?.data?.data?.specialist)
          )
        }
      }
    } catch (err) {
      console.error(err)
      toast.error(`Ошибка: ${err.message}`)
    } finally {
      setIsSubmitting(false) // Разблокируем кнопку отправки
    }
  }

  const isMobile = window.screen.width < 500

  if (error || spheresError || citiesError) {
    return toast.error("Ошибка при загрузке данных")
  }
  // selectedPhoto && console.log(URL.createObjectURL(selectedPhoto));

  let avatar = list?.avatar.replace("/storage/", "/storage/app/public/")

  return (
    <>
      {loading || spheresLoading || citiesLoading ? (
        <div className='min-h-[300px] flex justify-center items-center'>
          <TableSpinner />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='relative p-4 sm:p-[0px]'
        >
          <div className='flex items-end mb-[6px]'>
            <div className='w-32 mr-8 sm:mt-9'>
              <img
                className='w-full h-32 object-cover rounded-lg'
                src={
                  selectedPhoto
                    ? URL.createObjectURL(selectedPhoto)
                    : !loading
                      ? avatar ||
                        `//static.vecteezy.com/system/resources/previews/005/276/776/original/logo-icon-person-on-white-background-free-vector.jpg`
                      : ""
                }
                alt='avatar'
              />
            </div>
            <div>
              <Button
                {...avatarRootProps()}
                fullWidth
                sx={{
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "white",
                  },
                }}
                variant='outlined'
                color='secondary'
                startIcon={<PhotoCameraOutlined />}
              >
                <input
                  {...avatarInputProps()}
                  id='upload'
                  hidden
                  // accept='.jpg,.jpeg,.png,.svg'
                  accept='image/*'
                  type='file'
                  // onChange={(event) => {
                  //   const file = event.target.files[0]
                  //   if (file && !file.type.startsWith(".jpg,.jpeg,.png,.svg")) {
                  //     alert("Please upload only image files.")
                  //     event.target.value = ""
                  //   }
                  // }}
                />
                Изменить
              </Button>
            </div>
          </div>

          <div
            className='flex flex-col gap-6 sm:gap-0 sm:grid sm:grid-cols-3
        [&>label>input]:mt-1 [&>label]:flex [&>label]:flex-col sm:[&>label:nth-child(2)]:w-4/12
        [&>span]:font-medium [&>span]:text-2xl [&>span]:col-span-3  [&>span]:mt-10 sm:[&>span]:mb-5 
      '
          >
            <span>
              {list?.type === 1 ? "Данные компании" : "Личные данные"}
            </span>

            <div>
              <MyController
                control={control}
                name='category'
                rules={{ required: true }}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    disabled={true}
                    inputClassName='inputOutlined'
                    labelClassName='sm:mr-5'
                    labelText='Сфера деятельности'
                  />
                )}
              />
            </div>

            <div>
              <MyController
                control={control}
                name='name'
                rules={{ required: true }}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    required={true}
                    inputClassName='inputOutlined'
                    labelClassName='sm:mr-5'
                    labelText={list?.type === 1 ? "Название компании" : "Имя"}
                  />
                )}
              />
              {errors.name && (
                <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
              )}
            </div>

            {/* <div>
                <MyController
                  control={control}
                  name='surname'
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputBase
                      {...field}
                      required={true}
                      defaultValue={userSurname?.surname}
                      inputClassName='inputOutlined'
                      labelClassName='sm:mr-5'
                      labelText='Фамилия'
                    />
                  )}
                />
                {errors.name && (
                  <p className='ml-3 text-[12px] text-red'>
                    Заполните это поле
                  </p>
                )}
              </div> */}

            <div
              className={
                (list?.type === 1 ?? null)
                  ? "flex flex-col"
                  : "flex flex-col mt-2 sm:mt-[0] sm:mr-5"
              }
            >
              <label className='label-base'>Номер телефона*</label>
              <MyController
                name='phone'
                rules={{ required: "Заполните это поле" }}
                control={control}
                render={({ field }) => <PhoneInput {...field} />}
              />
              {errors?.phone && (
                <p className='ml-3  text-[12px] text-red'>
                  {errors?.phone?.message}
                </p>
              )}
            </div>

            <label className='label-base col-span-3 mt-8'>
              Описание
              <MyController
                name='description'
                control={control}
                render={({ field }) => <TextArea {...field} />}
              />
              {errors.description && (
                <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
              )}
            </label>
            <span>Адрес</span>

            <label className='label-base  sm:mr-5'>
              Город*
              <select
                className='outline-none rounded-lg border-lightGray hover:border-gray focus:border-gray font-normal placeholder:font-light placeholder:text-gray
                           border-[1px] border-solid px-3 py-[10px] text-normal text-blue bg-white disabled:text-gray disabled:hover:border-lightGray '
                {...register("city_id", {
                  required: true,
                })}
              >
                {cities?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.city_id && (
                <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
              )}
            </label>
            <div>
              <MyController
                name='street'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    required={true}
                    inputClassName='inputOutlined'
                    labelClassName='sm:mr-5'
                    labelText='Улица'
                    onKeyPress={(e) => {
                      const char = String.fromCharCode(e.keyCode || e.which)
                      if (!/^[A-Za-zА-Яа-яЁё\s]$/.test(char)) {
                        e.preventDefault()
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value
                      e.target.value = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, "")
                    }}
                  />
                )}
              />
              {errors.street && (
                <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
              )}
            </div>

            <div>
              <MyController
                name='building'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    required={true}
                    inputClassName='inputOutlined'
                    labelText='Дом'
                  />
                )}
              />
              {errors.building && (
                <p className='ml-3 text-[12px] text-red'>Заполните это поле</p>
              )}
            </div>

            <span>Соцсети</span>
            <MyController
              name='website'
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  inputClassName='inputOutlined'
                  labelClassName='sm:mr-5'
                  labelText='Ссылка на сайт'
                />
              )}
            />

            <MyController
              name='facebook'
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  inputClassName='inputOutlined'
                  labelClassName='sm:mr-5'
                  labelText='Ссылка на facebook'
                />
              )}
            />

            <MyController
              name='instagram'
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  inputClassName='inputOutlined'
                  labelText='Ссылка на instagram'
                />
              )}
            />

            <span>Ссылка для онлайн записи</span>
            <DeepLinkRedirect deepLink={list?.deep_link} control={control} />

            {/*<span className="flex gap-4 items-center">*/}
            {/*  Портфолио*/}
            {/*  /!*    <div*!/*/}
            {/*  /!*      onClick={helpPortfolio}*!/*/}
            {/*  /!*      className="cursor-pointer">*!/*/}
            {/*  /!*  <HelpOutlineIcon />*!/*/}
            {/*  /!*</div>*!/*/}
            {/*</span>*/}
            {/*<div className="flex flex-col col-span-3 min-h-[120px] h-full -mr-4">*/}
            {/*  <div*/}
            {/*    className={`grid grid-cols-3 sm:grid-cols-10 items-end h-full [&>div]:pr-4 [&>img]:pr-4*/}
            {/*                ${*/}
            {/*      isMobile*/}
            {/*        ? // styling every elems after first 3*/}
            {/*        "[&>:nth-child(1n+4)]:pt-4 "*/}
            {/*        : // styling every elems after first 10*/}
            {/*        "sm:[&>:nth-child(1n+11)]:pt-4"*/}
            {/*    }`}>*/}

            {/*    {list?.media?.length !== 0 ? list?.media?.filter(item => item.collection_name === "portfolio")?.map((item, index) =>*/}
            {/*      <img*/}
            {/*        key={index}*/}
            {/*        src={item.original_url}*/}
            {/*        className={"w-full h-32 object-cover rounded-lg"}*/}
            {/*        alt="portfolio" />,*/}
            {/*    ) : <></>}*/}

            {/*    {portfolio?.length > 0 &&*/}
            {/*      <>*/}
            {/*        {portfolio.map(item =>*/}
            {/*          <div className="relative" key={item.id}>*/}
            {/*            <img*/}
            {/*              className={"w-full h-28 object-cover rounded-lg"}*/}
            {/*              src={portfolio && URL.createObjectURL(item)}*/}
            {/*              alt="portfolio" />*/}
            {/*            <div className="absolute right-[-7px] top-2 cursor-pointer"*/}
            {/*                 onClick={() => deleteOnePhoto(item)}>*/}
            {/*              <CloseIcon />*/}
            {/*            </div>*/}
            {/*          </div>,*/}
            {/*        )}*/}
            {/*      </>}*/}

            {/*    <div className="h-full ml-3">*/}
            {/*      <label*/}
            {/*        htmlFor="uploadProtfolio"*/}
            {/*        className={` h-full w-full label-base flex flex-col whitespace-nowrap`}*/}
            {/*      >*/}
            {/*        <span>Загрузить фото</span>*/}
            {/*        <div*/}
            {/*          className="mt-1 w-full h-full border-[1px] border-dashed border-gray rounded-lg flex flex-col justify-center items-center text-xs :text-base px-1 py-1 cursor-pointer hover:text-black hover:border-black">*/}
            {/*          <CloudUpload />*/}
            {/*          Загрузить*/}
            {/*          <div {...portfolioRootProps()}>*/}
            {/*            <input*/}
            {/*              {...portfolioInputProps()}*/}
            {/*              accept="image/*"*/}
            {/*              hidden*/}
            {/*              type="file"*/}
            {/*              id="uploadProtfolio"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </label>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <button
            type='submit'
            disabled={postLoading || mediaLoading || isSubmitting}
            className={
              postLoading || mediaLoading
                ? "opacity-50 btnFilled w-fit py-3 px-4 mt-10 sm:mb-14"
                : "opacity-100   btnFilled w-fit py-3 px-4 mt-10 sm:mb-20  "
            }
          >
            Сохранить
          </button>
        </form>
      )}
      <AfterFirstStepModal open={openAfter} setOpen={setOpenAfter} />
      <FirstStepModal open={openFirst} setOpen={setOpenFirst} />
    </>
  )
}