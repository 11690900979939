import { useState } from "react"
import { MenuItem } from "@mui/material"

import { SelectContained } from "shared/ui/select/SelectContained"
import { GoBackBtn } from "../../../../widgets/auth/GoBackBtn"
import { useGetBusinessCategoriesQuery } from "pages/categories/slice"
import { useEffect } from "react"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"

export function SelectBusinessCategory({
  submit,
  backSlide,
  errorMsg,
  hidden4,
}) {
  const { data: businessCategories } = useGetBusinessCategoriesQuery()
  const [category, setCategory] = useState("")

  useEffect(() => {
    if (businessCategories) setCategory(businessCategories[0]?.category?.name)
    // if (businessCategories) {
    //   const firstCategory = businessCategories[0]?.category?.name
    //   if (firstCategory && firstCategory !== "Рестораны") {
    //     setCategory(firstCategory)
    //   }
    // }
  }, [businessCategories])

  function handleCategory(val) {
    setCategory(val?.target?.value)
  }
  function onSubmit() {
    submit(category)
    setTimer(5)
  }

  const [timer, setTimer] = useState(0)

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  return (
    <div
      className={`max-w-[344px] w-full relative mt-[10px] sm:mt-0 ${
        hidden4 && "hidden"
      }`}
    >
      <div className='text-center mx-auto sm:mb-12'>
        <h1 className='text-[27px] font-medium mb-6'>
          Выберите сферу деятельности
        </h1>
      </div>
      <div className='flex flex-col  sm:gap-0'>
        <label className='label-base w-full text-left [&>div]:mt-1'>
          Сфера деятельности
          <SelectContained value={category} onChange={handleCategory}>
            <MenuItem sx={{ color: "primary.main" }} value='' className='hidden' >Выберите раздел услуг</MenuItem>

             {businessCategories?.map((item, index) => (
             
                <MenuItem
                  key={index}
                  sx={{ color: "primary.main" }}
                  value={item?.category?.id}
                >
                  {item?.category?.name}
                </MenuItem>
              ))}
          </SelectContained>
        </label>
        <div>
          <button
            disabled={timer > 0}
            className='btnFilled w-full mt-8'
            onClick={onSubmit}
          >
            Далее
          </button>
          <GoBackBtn handleClick={backSlide} />
        </div>
      </div>
      {errorMsg && (
        <ErrorMsg title={errorMsg} className='-bottom-10 text-center' />
      )}
    </div>
  )
}
