import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders, accessToken } = getApiConfig()
const user = JSON.parse(localStorage.getItem("user"))
const isRestaurant = user?.restaurant_id

function photoPrepareHeaders(headers) {
  headers.set("Authorization", `Bearer ${accessToken}`)
  headers.set("Accept", "application/json")
  return headers
}

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  tagTypes: "Settings",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: photoPrepareHeaders,
  }),
  endpoints: (build) => ({
    getSpheres: build.query({
      query: () => `master/categories`,
    }),
    getSalonSettings: build.query({
      query: (params) => { 
        const url = isRestaurant
      ? `master/restaurants/${params.salon_id}`
      : `master/salons/${params.salon_id}`;
      return url;
    },
    }),
    // getSalonSettings: build.query({
    //   query: (params) => `master/salons/${params.salon_id}`,
    // }),
    editSettings: build.mutation({
      query: ({ id, ...body }) => {
        // url: `master/salons/${id}` ,
        const url = isRestaurant
          ? `master/restaurants/${id}`
          : `master/salons/${id}`;
        return {
          url,
        method: "PATCH",
        body,
      }
    },

    }),
    postSalonAvatar: build.mutation({
      query: ({ id, ...body }) => {
        // url: `master/salons/${id}/update-image`,
        const url = isRestaurant
          ? `master/restaurants/${id}/update-image`
          : `master/salons/${id}/update-image`;
        return {
          url,
        method: "POST",
        body: body.formData,
        }
      },
    }),
    getCities: build.query({
      query: () => `master/cities`,
    }),
   
  }),
})

export const {useGetSpheresQuery, useGetSalonSettingsQuery, useEditSettingsMutation, usePostSalonAvatarMutation, useGetCitiesQuery, useGetRestaurantAvatarQuery} = settingsApi
