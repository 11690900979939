import { Done } from "@mui/icons-material"

export function StepsBar({ mounted, pageNum }) {
  return (
    <div
      className={`${
        mounted ? "translate-x-0" : "translate-x-[-100%]"
      } bg-blue sm:h-screen sm:px-7 h-[80px]  sm:py-24  transition-transform duration-500 sm:flex sm:flex-col `}
    >
      <div className='hidden sm:flex'>
        <img src='./assets/logo/logo.svg' alt='logo' />
      </div>
      <p className='text-white text-2xl font-medium sm:mt-7 hidden sm:flex'>
        Смена пароля
      </p>
      <p className='sm:hidden flex text-white text-2xl font-medium sm:mt-7 mt-[6px] text-center mobile:ml-[90px]'>
        Сменить пароль
      </p>
      <div className='sm:mt-11 flex flex-row sm:flex-col -mt-[50px] mobile:justify-between  sm:gap-0  sm:ml-[0] '>
        <div className='flex w-fit '>
          <div className='flex flex-row sm:flex-col items-center gap-[28px] sm:gap-0  '>
            <span className={`circle ${pageNum >= 1 && "bg-white"} `}>
              <span
                className={`dot ${
                  pageNum === 1 && "bg-orange"
                } flex items-center justify-center `}
              >
                {pageNum > 1 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line h-28 bigMobile:h-32 sm:h-11 ml-9 bigMobile:ml-10 sm:ml-[-6px]  '>
              <span
                className={`subline rounded-[50px]  ${
                  pageNum > 1 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className=' hidden sm:flex sm:flex-col sm:ml-3 sm:mt-1'>
            {/* <p className="font-medium text-white">Смена пароля</p> */}
            <div className='text-white'>Номер телефона</div>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex  items-center flex-row sm:flex-col gap-[28px] sm:gap-0'>
            <span className={`circle ${pageNum >= 2 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum < 2
                    ? "bg-white"
                    : pageNum === 2
                      ? "bg-orange"
                      : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 2 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line h-28 bigMobile:h-32 sm:h-11 ml-9 bigMobile:ml-10 sm:ml-[-6px]  '>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 2 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className=' hidden sm:flex flex-col ml-3 mt-1 whitespace-nowrap overflow-hidden text-ellipsis'>
            <p className={`${pageNum >= 2 ? "text-white" : "text-gray"}`}>
              Код подтверждения
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex flex-row sm:flex-col items-center gap-4 sm:gap-0'>
            <span
              className={`circle ${
                pageNum === 3 ? "bg-white" : "bg-transparent"
              }`}
            >
              <span
                className={`dot ${
                  pageNum < 3 ? "bg-white" : "bg-orange"
                } flex items-center justify-center`}
              ></span>
            </span>
          </div>

          <div className='hidden sm:flex flex-col ml-3 mt-1'>
            <p className={`${pageNum < 3 ? "text-gray" : "text-white"}`}>
              Введите новый пароль
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}