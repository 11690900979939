import { DeleteModal } from "shared/ui/modal/DeleteModal"
import { useDeleteAnalyzeMutation } from "./analyzesApi"
import { toast } from "react-hot-toast"

export function RemoveAnalyze({ analyze, active, setActive }) {
  const [deleteAnalyze, { isLoading }] = useDeleteAnalyzeMutation()

  const handleDelete = async () => {
    try {
      await deleteAnalyze(analyze.id).unwrap()
      toast.success("Анализ удален", { position: "bottom-right" })
      setActive(false)
    } catch (err) {
      toast.error("Ошибка при удалении анализа")
    }
  }

  return (
    <DeleteModal
      active={active}
      setActive={setActive}
      loading={isLoading}
      text='Вы действительно хотите удалить запись?'
      onSubmit={handleDelete}
    />
  )
}
